import React from 'react';
import { google, slack } from './imports';
import './brand.css';
import Feature from '../feature/Feature';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <Feature title="powered by" />
    </div>
    <div>
      <img src={google} />
    </div>
    <div>
      <img src={slack} />
    </div>
  </div>
);

export default Brand;
