import React from 'react';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">THE WORLD IS RULED BY HUMANS,<br /> UNIVERSE BY ALIEN</h1>
      <br />
      <p>TRADE, EARN CRYPTO ON THE MOST POPULAR DECENTRALIZED PLATFORM IN THE GALAXY.</p>
    </div>

  </div>
);

export default Header;
