import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Alien Next" text="AlienNextFi is a decentralized exchange (DEX) built on the Arbitrum Chain that provides fast, secure, and low-cost tokens trading with total transparency and full control over your funds." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Alien to the moon</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="DEX" text="We so opinion friends me message as delight. Whole front do of plate heard oh ought." />
      <Feature title="Launchpad" text="At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. As put impossible own apartments b" />
      <Feature title="Earn" text="At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. As put impossible own apartments b" />
    </div>
  </div>
);

export default WhatGPT3;
