import React from 'react';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-content">
      <h4>Overflow sale</h4>
      <h1 className="gradient__text">Alien <br /> Launchpad</h1>
      <p>AlienFi Launchpad empowers new projects on the Arbitrum network to launch and list their tokens on AlienFi and gain visibility in the DeFi world of investments.</p>
      <h4>Request Early Access to Get Started</h4>
    </div>
  </div>
);

export default Possibility;
